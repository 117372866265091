@import url(//cdn-images.mailchimp.com/embedcode/classic-10_7.css);
@import url(https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Styles for all steps of profile creation */
.Profile {
  background-color: #ececec;
  min-height: 93vh;
  padding-bottom: 2em;
}

.Profile .steps-container,
.Profile .form-container {
  max-width: 60em;
  margin: 0 auto;
}

.Profile .steps-container {
  padding: 1.5em 1em 1em;
}

.Profile
  .steps-container
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(0, 0, 0, 0.25);
}

.Profile .form-container {
  box-shadow: 2px 2px 5px 2px lightgrey;
}

.Profile .FormElements-elements-container {
  margin-bottom: 1em;
}

.Profile .footer-container {
  text-align: right;
}

/* Styles for Describe Yourself step */

.Profile .ant-upload.ant-upload-drag {
  width: 74%;
  margin-right: 4%;
  display: inline-flex;
}
.Profile .ant-upload-list.ant-upload-list-picture-card {
  display: inline-flex;
  vertical-align: top;
  width: 22%;
}

.Profile .ant-upload-list-picture-card-container {
  margin: 0;
  width: 100%;
  height: 11em;
}

.Profile .ant-upload-list-picture-card-container .anticon.anticon-picture {
  padding-top: 15%;
}

.Profile .ant-upload-list-picture-card-container .ant-upload-list-item-name {
  bottom: 40px;
}

.Profile .ant-upload-list-item-name {
  white-space: normal;
}

.Profile .ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(0, 0, 0, 0.1);
}

/* Styles for Select Preferences step */
.Profile .ant-checkbox-group {
  width: 100%;
}

.Profile .ant-checkbox-group .ant-checkbox-wrapper {
  margin-bottom: 2em;
}

.Profile .footer-container .preview-button {
  margin-right: 1em;
}

.Profile .loading-indicator {
  display: flex;
  flex-direction: column;
}

.Profile .loading-indicator .anticon-loading {
  font-size: 3em;
}

.Profile .loading-indicator .loading-text {
  margin-top: 1em;
  text-align: center;
}

.Profile .screener-title {
  margin-bottom: 0;
}

.Profile .instructions .initial-question {
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}

.Profile .ant-typography blockquote {
  border-left: 4px solid #55a9a2;
  margin-top: 0;
  font-size: 0.95em;
}

.Profile .ant-typography blockquote p {
  margin-bottom: 0;
}

.Profile .anticon.anticon-info-circle {
  color: #55a9a2;
  font-size: 1.125em;
}

.Profile .ant-input-number.ant-input-number-lg,
.Profile .ant-input-number-affix-wrapper {
  width: 100%;
}

.Profile .FormElements-element-switch + .FormElements-element-input {
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .Profile .ant-upload.ant-upload-drag {
    width: 65%;
  }
  .Profile .ant-upload-list.ant-upload-list-picture-card {
    width: 30%;
  }
  .Profile .ant-upload-list-picture-card-container {
    height: 12.5em;
  }
  .Profile .ant-upload-list-picture-card-container .anticon.anticon-picture {
    padding-top: 30%;
  }
  .Profile .ant-upload-list-picture-card-container .ant-upload-list-item-name {
    bottom: 60px;
  }

  .Profile .footer-container .next-button {
    width: 100%;
  }

  .Profile .ant-typography blockquote p {
    display: inline;
  }

  .Profile .ant-col-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .Profile .footer-container .preview-button {
    margin-bottom: 1em;
  }

  .Profile .footer-container .preview-button,
  .Profile .footer-container .search-button {
    width: 100%;
  }
}

.FormElements-element.datePicker .ant-picker {
  width: 100%;
}

.FormElements-input-tags {
  margin-top: 0.5em;
}

.FormElements .ant-select {
  width: 100%;
}

.FormElements .upload-dragger-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormElements .upload-dragger-container .upload-dragger-label {
  border: 1px solid #d9d9d9; /* antd gray-5 from https://ant.design/docs/spec/colors */
}

.FormElements .upload-dragger-container .upload-dragger-label:focus,
.FormElements .upload-dragger-container .upload-dragger-label:hover {
  outline: 0; /* antd gray-5 from https://ant.design/docs/spec/colors */
  border: 1px solid #40a9ff;
}

.FormElements .upload-dragger-container .upload-dragger-label {
  width: 100%;
  padding: 0em 0 1em;
}

.FormElements .upload-dragger-container .upload-dragger-icon,
.FormElements .upload-dragger-container .upload-dragger-title,
.FormElements .upload-dragger-container .upload-dragger-subtitle {
  display: block;
  text-align: center;
}

.FormElements .upload-dragger-container .upload-dragger-icon {
  font-size: 42px;
}

.FormElements .upload-dragger-container .upload-dragger-icon,
.FormElements .upload-dragger-image-icon--placeholder,
.FormElements .upload-dragger-image-icon--loading {
  color: #40a9ff; /* antd blue-5 from https://ant.design/docs/spec/colors */
}

.FormElements .upload-dragger-container .upload-dragger-title {
  font-size: 1.25em;
}

.FormElements .upload-dragger-container .upload-dragger-subtitle {
  color: rgba(0, 0, 0, 0.45);
}

.FormElements .upload-dragger-image {
  width: 10em;
  margin-left: 1em;
}

.FormElements .upload-dragger-image-icon--placeholder,
.FormElements .upload-dragger-image-icon--loading {
  text-align: center;
  margin-bottom: 1em;
  font-size: 2.25em;
  opacity: 0.7;
}

.FormElements .upload-dragger-image-placeholder {
  text-align: center;
  padding: 1em 1em 2em;
  border: 1px solid #d9d9d9;
  height: 100%;
}

.FormElements .upload-image-final {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.FormElements .upload-image-final__icon {
  text-align: right;
}

.FormElements .upload-image-final__image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
  min-height: 10em;
}

.FormElements .upload-dragger-input-element {
  display: none;
}

.FormElements .upload-dragger-error-message {
  margin-top: 1em;
}

.FormElements .FormElements-element-radio {
  margin-top: 1em;
}

.FormElements .FormElements-radio-label {
  display: block;
}

.FormElements .FormElements-element-switch {
  display: flex;
}

.FormElements .FormElements-switch-label {
  margin-right: 0.5em;
}

.FormElements .float-label {
  position: relative;
  display: inline-block;
  width: 100%;
}

.FormElements .label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.FormElements .label.inline {
  color: gray;
}

.FormElements .label.above {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  z-index: 2;
}

.FormElements .label .required {
  color: red;
  font-family: monospace;
}

@media only screen and (max-width: 768px) {
  .FormElements .upload-dragger-container .upload-dragger-title {
    font-size: 1em;
  }
  .FormElements .upload-dragger-container .upload-dragger-subtitle {
    font-size: 0.75em;
  }
  .FormElements .upload-dragger-image-placeholder {
    padding: 1em;
  }
}

.ant-avatar .user-image {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.Preview img {
  width: 100%;
}

.Preview .profile-name {
  margin-bottom: 0;
}

.Preview .pronoun {
  text-transform: capitalize;
}

.Preview .image-container {
  text-align: center;
  margin-right: 1em;
  max-width: 12em;
}

.Preview .responsive-areas {
  display: flex;
  flex-direction: column;
}

.Preview .profile-name {
  font-weight: bold;
  font-size: 1.625em;
  line-height: 1.125em;
}

.Preview .ant-avatar.ant-avatar-circle.ant-avatar-image {
  width: 10em;
  height: 10em;
}

.Preview .profile-heading {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Preview .profile-heading.with-image {
  max-width: 50%;
}

.Preview .profile-heading p {
  margin-bottom: 0;
}

.Preview .ant-collapse {
  width: 100%;
  margin-top: 1em;
}

.Preview .ant-collapse .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 15%;
  padding: 1em;
}

.Preview .ant-tag {
  margin-bottom: 0.125em;
}

.Preview .collapse-panel-header {
  text-decoration: underline;
  font-weight: bold;
}

.Preview .fee-text {
  font-weight: bold;
  font-size: 0.8em;
}

@media all and (max-width: 768px) {
  .Preview .ant-avatar.ant-avatar-circle.ant-avatar-image {
    width: 8em;
    height: 8em;
  }

  .profile-preview-drawer .ant-drawer-body {
    padding: 1em;
  }

  .profile-preview-drawer
    .ant-descriptions-bordered
    .ant-descriptions-item-label {
    padding: 0.5em;
  }

  .profile-preview-drawer
    .ant-descriptions-bordered
    .ant-descriptions-item-content {
    padding: 0.5em 1em;
  }
}

.Header {
  font-size: 1.5em;
  box-shadow: 1px 1px 5px #707070;
  padding: 0.5em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.Header-Right {
  display: flex;
  font-size: 0.8em;
}

.Header a {
  color: black;
}

.Header .logo {
  width: 0.9em;
  height: 0.9em;
  margin-right: 0.125em;
}

.Header .animated-logo {
  position: relative;
}

.Header .animated-logo .blinking-logo-dot {
  position: absolute;
  right: 31%;
  top: 15%;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}

.Header .menu-icon {
  font-size: 16px;
}

.Header .user-avatar {
  width: 1.25em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin-left: 0;
}

.Header .user-avatar .anticon.anticon-user {
  vertical-align: 0.35em;
  font-size: 0.7em;
}

.Header .user-avatar .ant-avatar {
  width: 22px;
  height: 22px;
}

.header-dropdown-menu .sign-out-link {
  color: black;
}

@media all and (max-width: 800px) {
  .Header {
    font-size: 1.125em;
  }
  .Header .anticon.anticon-mail {
    margin-top: 0.25em;
  }

  .Header .animated-logo .blinking-logo-dot {
    width: 3px;
    height: 3px;
  }
}

html {
  scroll-behavior: smooth;
}

.homepage__container h1 {
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepage__container h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepage__container h3 {
  margin-bottom: 0;
  font-weight: bold;
}

#jubily-interest-form {
  scroll-margin-top: 150px;
}

.homepage__container button,
#mc_embed_signup .button {
  background-color: #0072ff;
  border: none;
  color: white !important;
  padding: 0.5em 0.75em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 15em;
  font-size: 1.125em;
  margin-top: 1em;
  cursor: pointer;
  height: auto;
  line-height: normal;
  line-height: initial;
}

.homepage__container button:hover,
#mc_embed_signup .button:hover {
  opacity: 0.8;
  background-color: #0072ff;
}

.homepage__container a {
  text-decoration: none;
  color: #000;
  color: initial;
}

.homepage__container input {
  padding-left: 1em;
}

.homepage__container input:focus {
  box-shadow: 0.5px 0.5px #1f0c2f;
  outline: none;
}

#mc_embed_signup form {
  padding-left: 0;
}

#mc_embed_signup .mc-field-group + .mc-field-group {
  margin-left: 2%;
}
#mc_embed_signup .mc-field-group {
  width: 100%;
  padding-bottom: 0;
}

#mc_embed_signup .mc-field-group__container {
  padding-bottom: 1%;
}

#mc_embed_signup .mc-field-group.input-group ul li {
  flex: 0 1 50%;
}

#mc_embed_signup .indicates-required {
  margin-right: 0;
}

.flex {
  display: flex;
}

.flex--column {
  display: flex;
  flex-direction: column;
}

.flex--row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex__grow {
  flex: 1 1 auto;
}

.flex__wrap {
  flex-wrap: wrap;
}

.centered {
  text-align: center;
}

.justify-content__center {
  justify-content: center;
}
.justify-items__space-between {
  justify-content: space-between;
}

.justify-items__space-around {
  justify-content: space-around;
}

.justify-items__space-evenly {
  justify-content: space-evenly;
}

.align-self__center {
  align-self: center;
}

.align-items__center {
  align-items: center;
}

.align-items__flex-end {
  align-items: flex-end;
}

.align-items__baseline {
  align-items: baseline;
}

.bold {
  font-weight: bold;
}

.large-title-text {
  line-height: 1.125em;
}

.homepage__section-text {
  position: relative;
  right: 1.5em;
}

.homepage__section-image {
  width: 45%;
  border: 20px solid #ffc101;
  min-height: 550px;
  object-fit: cover;
  background-color: lightslategray;
}

.homepage__section-image.position-right {
  object-position: right;
}

.homepage__section.image-right {
  background-color: #f1f7ff;
}

.homepage__section.image-right .homepage__section-image {
  border-right: 0;
}

.homepage__section.image-left .homepage__section-image {
  border-left: 0;
}

.homepage__section.position-relative {
  position: relative;
}

.homepage__section.position-relative .phone-image {
  position: absolute;
  width: 18%;
  bottom: 3%;
  right: 40%;
}

.homepage__section.section--image-text-block.gradient-down-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
}

.homepage__section.section--image-text-block.gradient-up-light {
  background: rgb(241, 247, 255);
  background: linear-gradient(
    0deg,
    rgba(241, 247, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.homepage__section.section--image-text-block.gradient-left-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
}

.homepage__section.next-step {
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 9em 0;
}

.homepage__section.next-step .logos-container {
  width: 54%;
}

.homepage__section.next-step img.nsf {
  width: 100px;
  margin-right: 20px;
  margin-left: -5px;
}

.homepage__section.next-step img.blackstone {
  object-fit: contain;
  width: 300px;
}

.homepage__section.next-step img.black-ambition {
  width: 250px;
  margin-left: -25px;
}

.homepage__section.next-step img.berkeley-law {
  height: 75px;
}

.homepage__section.next-step img.skydeck {
  margin-left: -10px;
  height: 85px;
}

.homepage__section.next-step .homepage__section-text {
  width: 50%;
  border-left: 20px solid #ffc101;
  margin-right: 0;
}

.homepage__section.next-step .homepage__section-text .form-container {
  width: 80%;
  margin-left: auto;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes from-left {
  0% {
    right: 1.5em;
  }
  100% {
    right: 0;
  }
}

@keyframes from-left {
  0% {
    right: 1.5em;
  }
  100% {
    right: 0;
  }
}

.homepage__section.section--image-text-block {
  padding: 60px 0;
  justify-content: space-between;
}

.homepage__container {
  margin: 0 auto;
  max-width: 120em;
  width: 100%;
}

.homepage__section + .homepage__section {
  margin-top: 5em;
}

.homepage__section.image-left .homepage__section-text {
  margin-right: 10em;
}

.homepage__section.image-right .homepage__section-text {
  margin-left: 10em;
}

.homepage__section-text {
  width: 30%;
}

.homepage__section-block-text {
  line-height: 2em;
  width: 90%;
  margin: 0 auto;
  max-width: 900px;
}

.homepage__section-block-text .large-title-text {
  text-align: center;
  font-size: 2em;
}

.block-text__sections {
  padding: 2em;
  justify-content: space-between;
}

.block-text__section {
  width: 48%;
  background-color: #f1f7ff;
  padding: 2em;
  position: relative;
}

.block-text__section .coming-soon-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: #ff4c4c;
  border-radius: 100px;
  font-weight: bold;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 15px;
}

.block-text__section ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}

.block-text__section ul li {
  padding: 10px 0;
}

.homepage__container p {
  margin-top: 0.5em;
}

.homepage__section-title {
  width: 45%;
}

.homepage__container p,
.homepage__container li {
  font-size: 1.25em;
  line-height: 1.75;
}

@media all and (max-width: 1150px) {
  .homepage__container h1 {
    font-size: 2em;
  }

  .homepage__container h2 {
    font-size: 1.75em;
  }

  .homepage__container p,
  .homepage__container li {
    font-size: 1.125em;
  }

  .homepage__section.image-right .homepage__section-text {
    margin-left: 2em;
  }

  .homepage__section.image-left .homepage__section-text {
    margin-right: 2em;
  }

  .homepage__section-text {
    width: 40%;
  }

  .homepage__section-block-text .large-title-text {
    text-align: left;
  }

  .homepage__section-block-text .large-title-text br {
    display: none;
  }

  .block-text__sections {
    padding: 0;
  }

  .homepage__section.next-step {
    width: 95%;
  }

  .homepage__section.next-step .homepage__section-text {
    margin-left: 2em;
  }

  .homepage__section.next-step img.nsf,
  .homepage__section.next-step img.blackstone,
  .homepage__section.next-step img.black-ambition,
  .homepage__section.next-step img.berkeley-law {
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .homepage__section.next-step img.nsf {
    width: 20%;
    margin-left: 0;
  }

  .homepage__section.next-step img.black-ambition {
    margin-left: -10px;
  }

  .homepage__section.next-step img.skydeck {
    width: 100%;
    height: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 800px) {
  .homepage__section.position-relative .phone-image {
    display: none;
  }

  .homepage__container button,
  #mc_embed_signup .button {
    width: 100%;
  }
  .homepage__container {
    overflow: hidden;
  }

  .homepage__section.image-left,
  .homepage__section.image-right {
    flex-direction: column-reverse;
  }

  .homepage__section-image {
    width: 85%;
    border: 0;
    border-bottom: 20px solid #ffc101;
    min-height: auto;
  }

  .homepage__section-block-text .large-title-text {
    text-align: left;
  }

  .homepage__container p,
  .homepage__container li {
    font-size: 1.125em;
  }

  .homepage__section-text {
    width: auto;
  }

  .homepage__section-text {
    padding-left: 3em;
    padding-right: 2em;
  }

  .homepage__section-block-text {
    padding: 2em;
  }

  .block-text__sections {
    flex-direction: column;
    padding: 3em 0 0;
  }

  .block-text__section {
    margin-bottom: 20px;
    width: 100%;
  }

  .homepage__section-title {
    width: 100%;
  }

  .homepage__section-title {
    padding-bottom: 0.5em;
  }

  .large-title-text {
    max-width: 100%;
    font-size: 2.5em;
    line-height: 1.25em;
  }

  .homepage__section.section--image-text-block .homepage__section-text {
    padding: 1em;
    margin: 0 auto 0 10%;
    width: 90%;
  }

  .homepage__container #mc_embed_signup .mc-field-group.input-group ul.flex {
    display: block;
  }

  .homepage__section.next-step {
    width: 90%;
    flex-direction: column;
    padding: 5em 1em 0;
  }

  .homepage__section.next-step .logos-container {
    width: 100%;
  }

  .homepage__section.next-step .homepage__section-text {
    border: none;
    border-top: 20px solid #ffc101;
    padding: 30px 0 0;
    margin: 30px 0 0 40px;
    width: 100%;
  }

  .homepage__section.next-step .homepage__section-text .form-container {
    width: 100%;
  }

  #jubily-interest-form {
    scroll-margin-top: 100px;
  }
}

.interest-form .mc-field-group__container .FormElements {
  width: 100%;
}

.interest-form .FormElements-element-inputRow {
  margin-bottom: 1em;
}

.interest-form .mc-field-group {
  margin-top: 1em;
}

.interest-form .ant-space.ant-space-horizontal.ant-space-align-center {
  align-items: flex-start;
}

.Login .FormElements-element-input,
.Signup .FormElements-element-input {
  margin-bottom: 1em;
}

.loginImage {
  background-color: #f8f8f8;
  background-image: url(/static/media/provider-and-baby.26ce5f88.jpg);
  height: 93.25vh;
  background-size: cover;
  background-position: top;
}

.signupImage {
  background-image: url(/static/media/provider-patient-and-clipboard.624dd128.jpg);
}

.Signup .redirectText,
.Login .redirectText {
  text-align: center;
  margin-top: 1em;
}

.Signup .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-bottom: 1em;
}

.Login .link {
  color: #1890ff;
  cursor: pointer;
}

.ant-message .Login__password-reset-error-message {
  margin-top: 1em;
}

.LoginMessages .login-error {
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .loginImage {
    height: 45vh;
  }
  .loginForm-InputContainer {
    padding: 2em 0;
  }
}

.about__container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.about__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.about__content h1 {
  text-align: center;
  padding-bottom: 0.5em;
  border-bottom: 0.5px solid black;
  margin: 1em 15vw;
}

.about__content p {
  font-size: 1.5em;
  line-height: 2em;
}

.about__content p + p {
  margin-top: 1em;
}

@media all and (max-width: 800px) {
  .about__container {
    padding-top: 5em;
  }
  .about__content {
    padding: 3em;
    font-size: 0.75em;
  }
}

.team__container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.team__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.team__content .team__content-row {
  margin-bottom: 5em;
}

.team__content h1 {
  margin: 1em auto 1.5em;
  text-align: center;
  padding-bottom: 0.5em;
  border-bottom: 0.5px solid black;
  margin: 1em 15vw;
}

.team__content h2 {
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 2em;
}

.team__content .flex-2 {
  margin: 2em;
}

.team__content .flex-3 {
  flex: 1 1 33%;
  margin: 2em;
}

.team__content h3 {
  font-weight: bold;
  margin-bottom: 1em;
}

.team__content p {
  line-height: 1.5em;
  font-size: 0.9em;
}

.team__content img,
.team__content .member {
  max-width: 10em;
  margin-bottom: 0.5em;
}

.team__content .flex-grid-3 .member {
  max-width: 15em;
}

.team__content .member {
  margin: 0 1.5em 2em 0;
}

@media all and (max-width: 800px) {
  .team__container {
    padding-top: 5em;
  }
  .team__content {
    padding: 3em;
    font-size: 0.85em;
  }
  .team__content h1 {
    margin: 1em 1vw;
  }
  .team__content h2 {
    margin: 1em 0.25em;
  }
  .team__content-row .flex.justify-items__space-evenly {
    justify-content: space-between;
  }
  .team__content .member {
    margin-right: 0;
  }
  .team__content img,
  .team__content .member,
  .team__content .flex-grid-3 img,
  .team__content .flex-grid-3 .member {
    max-width: 9em;
  }
  .team__content .flex-grid-2 {
    justify-content: space-between;
  }
}

.TermsOfUse__container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.TermsOfUse__container .TermsOfUse__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text {
  text-align: center;
  margin-bottom: 1em;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text p {
  text-align: center;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text h1,
.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text p {
  font-weight: bold;
  margin: 0;
}

.TermsOfUse__container .TermsOfUse__content h1,
.TermsOfUse__container .TermsOfUse__content h2,
.TermsOfUse__container .TermsOfUse__content h3 {
  font-size: 1em;
}

.TermsOfUse__container .TermsOfUse__content p {
  line-height: 2;
  text-align: justify;
}

.TermsOfUse__container .TermsOfUse__content p + p {
  margin-top: 1em;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__contact-info span {
  display: block;
}

.TermsOfUse__container .TermsOfUse__content .text-indent--2 {
  text-indent: 2em;
}

.TermsOfUse__container .TermsOfUse__content .text-indent--3 {
  text-indent: 3em;
}

.TermsOfUse__container .TermsOfUse__content .italic {
  font-style: italic;
}

@media all and (max-width: 800px) {
  .TermsOfUse__container {
    padding-top: 5em;
  }
  .TermsOfUse__container .TermsOfUse__content {
    padding: 3em;
  }
}

.PrivacyPolicy {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.PrivacyPolicy .PrivacyPolicy__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.PrivacyPolicy .PrivacyPolicy__content h1 {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  font-weight: bold;
}

.PrivacyPolicy .PrivacyPolicy__content h2,
.PrivacyPolicy .PrivacyPolicy__content h3 {
  font-size: 1em;
  font-weight: bold;
}

.PrivacyPolicy .PrivacyPolicy__content p {
  line-height: 2;
  text-align: justify;
}

.PrivacyPolicy .PrivacyPolicy__content p + p {
  margin-top: 1em;
}

.PrivacyPolicy .PrivacyPolicy__content .TermsOfUse__contact-info span {
  display: block;
}

.PrivacyPolicy .PrivacyPolicy__content .text-indent--2 {
  text-indent: 2em;
}

.PrivacyPolicy .PrivacyPolicy__content .text-indent--3 {
  text-indent: 3em;
}

.PrivacyPolicy .PrivacyPolicy__content .italic {
  font-style: italic;
}

@media all and (max-width: 800px) {
  .PrivacyPolicy {
    padding-top: 5em;
  }
  .PrivacyPolicy .PrivacyPolicy__content {
    padding: 3em;
  }
}

.search-providers {
  display: flex;
  min-height: 90vh;
}

.search-providers .section-header {
  font-size: 1.5em;
}
.search-providers .clear-filters {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 2em;
}
.search-providers .more-filters {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.search-providers .filters-section {
  padding: 1em 2em;
}

.search-providers .facet-header {
  margin-bottom: 1em;
  font-size: 1.125em;
}

.search-providers .facet-header-slider {
  margin-bottom: 0.4em;
  font-size: 1.125em;
}

.search-providers .disable-slider {
  font-size: 0.75em;
  margin-left: 0.5em;
}

.search-providers .filters-checkbox {
  margin-bottom: 1em;
}

.filters-checkbox {
  text-transform: capitalize;
}

.search-providers .providers-section {
  padding: 1em 2em;
  flex: 1 0 80%;
}

.search-providers .provider {
  margin-bottom: 2em;
  width: 31%;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
}

.search-providers .provider-badge {
  position: absolute;
  top: 1em;
  left: 1em;
}

.search-providers .message-card-action {
  display: flex;
  justify-content: center;
}

.search-providers .message-card-action .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.search-providers .message-card-action .ant-checkbox {
  display: none;
}

.search-providers .message-card-action .ant-checkbox.ant-checkbox-checked {
  display: block;
}

.search-providers .provider-image {
  height: 20em;
  background-size: cover;
  background-position: center;
}

.search-providers .ant-card .ant-skeleton.provider-image {
  display: flex;
}

.search-providers .ant-card .ant-skeleton.provider-image .ant-skeleton-button {
  flex: 1 1;
  padding-bottom: 80%;
}

.search-providers .provider-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.search-providers .provider-details .provider-name {
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.125em;
  margin-bottom: 0;
  /* Treatment for long names */
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.search-providers .provider-details .provider-name span {
  margin-right: 0.25em;
}

.search-providers .provider-details .provider-responsiveness {
  margin: 1em 0;
}

.search-providers
  .provider-details
  .provider-responsiveness
  .provider-responsiveness-header {
  color: #00000073;
  font-weight: bold;
}

.search-providers
  .provider-details
  .provider-responsiveness
  .provider-responsiveness-body {
  font-size: 0.75em;
}

.search-providers
  .provider-details
  .provider-responsiveness
  .provider-responsiveness-body
  .ant-tag {
  margin-top: 0.25em;
}

.search-providers .provider .provider-pronouns {
  font-size: 1.0125em;
}

.search-providers .provider .provider-description {
  margin-top: 0.5em;
}

.search-providers .mobile-filters-button {
  display: none;
}

.search-providers .ant-rate {
  font-size: 1em;
  color: #55a9a2;
}

.search-providers .ant-rate-star:not(:last-child) {
  margin-right: 0;
}

.search-providers .ratings-fraction {
  font-size: 95%;
}

.search-providers .providers-list {
  display: flex;
  flex-wrap: wrap;
}

.search-providers .overall-ratings {
  text-align: right;
}

.search-providers .overall-ratings-label {
  font-size: 75%;
}

.search-providers .error-trigger {
  width: 100%;
  height: 100%;
  position: absolute;
}

.search-providers .ant-card-actions > li {
  color: black;
}

.search-providers .ant-card-body {
  flex: 1 1;
}

@media all and (max-width: 1024px) {
  .search-providers .providers-section {
    padding: 1em 2em;
    flex: 1 0 75%;
  }
  .search-providers .provider {
    width: 48%;
  }
}

@media all and (max-width: 765px) {
  .search-providers {
    font-size: 80%;
    display: block;
  }

  .search-providers .filters-section {
    display: none;
  }

  .search-providers .mobile-filters-button {
    display: block;
    width: 90%;
    margin: 1em auto 0;
  }
  .search-providers-filters-drawer .ant-drawer-title {
    font-size: 1.5em;
  }
  .search-providers-filters-drawer .facet-header {
    margin-bottom: 1em;
    font-size: 1.125em;
  }
  .search-providers-filters-drawer .filters-checkbox {
    margin-bottom: 1em;
  }
  .search-providers-filters-drawer .clear-filters {
    text-decoration: underline;
    cursor: pointer;
  }

  .search-providers .provider {
    width: 100%;
  }

  .search-providers .provider-image {
    height: 45em;
  }

  .search-providers .ant-card-body {
    padding: 1em;
  }

  .search-providers .ant-card-body {
    min-height: auto;
  }
}

@media all and (max-width: 500px) {
  .search-providers .provider-image {
    height: 20em;
  }
}

.Messages .message-container {
  margin-bottom: 3em;
  align-items: flex-start;
}

.Messages .group-messages-header {
  position: fixed;
  right: 0;
  width: 80%;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  z-index: 1;
}

.Messages .group-messages-container {
  padding-top: 7em;
}

.Messages .message-container.is-author {
  flex-direction: row;
  text-align: right;
}

.Messages .message-container.is-not-author {
  flex-direction: row-reverse;
}

.Messages .message-text {
  border: 1px solid lightgray;
  padding: 0.5em 1em;
  display: inline-block;
}

.Messages .message-container.is-author .message-text {
  margin-left: auto;
}

.Messages .message-author {
  display: flex;
  align-items: center;
}

.Messages .message-container.is-author .message-author {
  margin-left: 1em;
}

.Messages .message-container.is-not-author .message-author {
  margin-right: 1em;
  flex-direction: row-reverse;
  margin-left: 2em;
}

.Messages .message-container.is-author .message-author .author-name {
  margin-right: 0.5em;
}

.Messages .message-container.is-not-author .message-author .author-name {
  margin-left: 0.5em;
}

.Messages .message-col {
  margin-left: 0;
  max-width: 100%;
  flex: auto;
}

.Messages .input-container {
  width: 80%;
  margin-top: 1em;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 1em 2em;
}

.Messages .ant-page-header-back {
  display: none;
}

@media all and (max-width: 800px) {
  .Messages .input-container,
  .Messages .group-messages-header {
    width: 100%;
  }
  .Messages .ant-page-header-back {
    display: block;
  }

  .Messages .group-messages-container {
    margin-bottom: 7em;
  }
}

.GroupList .ant-badge {
  width: 100%;
}

.GroupList .group {
  display: flex;
  padding: 1em;
  flex-direction: column;
  border-radius: 5px;
  margin: 1em 0.5em;
}

.GroupList .group.selected,
.GroupList .group.selected:hover {
  background-color: #f0f0f0;
}

.GroupList .group:hover {
  background-color: #d9d9d9;
}

.GroupList .group .group__avatar-names-container {
  display: flex;
  align-items: center;
}

.GroupList .group__member-names,
.GroupList .group__last-message {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.GroupList .group__member-names {
  margin-left: 0.5em;
}

.GroupList .group__last-message,
.GroupList .message-timestamp .timestamp__date {
  margin-top: 0.5em;
  padding-bottom: 0.125em;
}

.GroupList .message-timestamp {
  font-size: 0.8em;
}

.GroupList .message-timestamp .timestamp__time {
  margin-top: 0.25em;
}

@media all and (max-width: 800px) {
  .GroupList .group.selected {
    background-color: transparent;
  }
  .GroupList .group {
    border-radius: 0;
    border-bottom: 1px solid #d9d9d9;
  }
}

.Messaging {
  display: flex;
  min-height: 80vh;
}

.Messaging .GroupList-container {
  width: 20%;
  cursor: pointer;
  border-right: 1px solid #d9d9d9;
  position: fixed;
  height: 100%;
}

.Messaging .GroupList-container:hover {
  overflow: auto;
}

.Messaging .Messages-container {
  width: 80%;
  margin-left: 20%;
  padding: 0 0 7em;
}

.Messaging .no-messages {
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 800px) {
  .Messaging .GroupList-container {
    width: 100%;
    display: none;
    position: static;
  }
  .Messaging .GroupList-container.is-visible {
    display: block;
  }
  .Messaging .Messages-container {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
    display: none;
  }
  .Messaging .Messages-container.is-visible {
    display: block;
  }
}

.Footer {
  padding: 5em;
  padding-top: 1em;
}

.Footer .footer__content {
  border-top: 1px solid #707070;
  padding-top: 1em;
}

.Footer .footer__content p {
  margin-bottom: 0.25em;
}

.Footer .footer__content a {
  color: #000;
  color: initial;
}

.Footer .footer__section {
  display: flex;
}

.Footer .footer__divider {
  padding: 0 1em;
}

@media all and (max-width: 800px) {
  .Footer {
    padding: 2em;
  }

  .Footer .footer__content a {
    width: 100%;
    text-align: left;
  }

  .Footer .footer__section {
    flex-direction: column;
  }
  .Footer .footer__divider {
    display: none;
  }
}

body {
  font-family: "Gelasio", serif;
}

.ant-result.ant-result-404 {
  min-height: 80vh;
}

