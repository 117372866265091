.Login .FormElements-element-input,
.Signup .FormElements-element-input {
  margin-bottom: 1em;
}

.loginImage {
  background-color: #f8f8f8;
  background-image: url("../../assets/provider-and-baby.jpg");
  height: 93.25vh;
  background-size: cover;
  background-position: top;
}

.signupImage {
  background-image: url("../../assets/provider-patient-and-clipboard.jpg");
}

.Signup .redirectText,
.Login .redirectText {
  text-align: center;
  margin-top: 1em;
}

.Signup .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-bottom: 1em;
}

.Login .link {
  color: #1890ff;
  cursor: pointer;
}

.ant-message .Login__password-reset-error-message {
  margin-top: 1em;
}

.LoginMessages .login-error {
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .loginImage {
    height: 45vh;
  }
  .loginForm-InputContainer {
    padding: 2em 0;
  }
}
