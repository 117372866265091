.Footer {
  padding: 5em;
  padding-top: 1em;
}

.Footer .footer__content {
  border-top: 1px solid #707070;
  padding-top: 1em;
}

.Footer .footer__content p {
  margin-bottom: 0.25em;
}

.Footer .footer__content a {
  color: initial;
}

.Footer .footer__section {
  display: flex;
}

.Footer .footer__divider {
  padding: 0 1em;
}

@media all and (max-width: 800px) {
  .Footer {
    padding: 2em;
  }

  .Footer .footer__content a {
    width: 100%;
    text-align: left;
  }

  .Footer .footer__section {
    flex-direction: column;
  }
  .Footer .footer__divider {
    display: none;
  }
}
