.Header {
  font-size: 1.5em;
  box-shadow: 1px 1px 5px #707070;
  padding: 0.5em;
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.Header-Right {
  display: flex;
  font-size: 0.8em;
}

.Header a {
  color: black;
}

.Header .logo {
  width: 0.9em;
  height: 0.9em;
  margin-right: 0.125em;
}

.Header .animated-logo {
  position: relative;
}

.Header .animated-logo .blinking-logo-dot {
  position: absolute;
  right: 31%;
  top: 15%;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}

.Header .menu-icon {
  font-size: 16px;
}

.Header .user-avatar {
  width: 1.25em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin-left: 0;
}

.Header .user-avatar .anticon.anticon-user {
  vertical-align: 0.35em;
  font-size: 0.7em;
}

.Header .user-avatar .ant-avatar {
  width: 22px;
  height: 22px;
}

.header-dropdown-menu .sign-out-link {
  color: black;
}

@media all and (max-width: 800px) {
  .Header {
    font-size: 1.125em;
  }
  .Header .anticon.anticon-mail {
    margin-top: 0.25em;
  }

  .Header .animated-logo .blinking-logo-dot {
    width: 3px;
    height: 3px;
  }
}
