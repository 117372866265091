.about__container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.about__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.about__content h1 {
  text-align: center;
  padding-bottom: 0.5em;
  border-bottom: 0.5px solid black;
  margin: 1em 15vw;
}

.about__content p {
  font-size: 1.5em;
  line-height: 2em;
}

.about__content p + p {
  margin-top: 1em;
}

@media all and (max-width: 800px) {
  .about__container {
    padding-top: 5em;
  }
  .about__content {
    padding: 3em;
    font-size: 0.75em;
  }
}
