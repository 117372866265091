/* Styles for all steps of profile creation */
.Profile {
  background-color: #ececec;
  min-height: 93vh;
  padding-bottom: 2em;
}

.Profile .steps-container,
.Profile .form-container {
  max-width: 60em;
  margin: 0 auto;
}

.Profile .steps-container {
  padding: 1.5em 1em 1em;
}

.Profile
  .steps-container
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(0, 0, 0, 0.25);
}

.Profile .form-container {
  box-shadow: 2px 2px 5px 2px lightgrey;
}

.Profile .FormElements-elements-container {
  margin-bottom: 1em;
}

.Profile .footer-container {
  text-align: right;
}

/* Styles for Describe Yourself step */

.Profile .ant-upload.ant-upload-drag {
  width: 74%;
  margin-right: 4%;
  display: inline-flex;
}
.Profile .ant-upload-list.ant-upload-list-picture-card {
  display: inline-flex;
  vertical-align: top;
  width: 22%;
}

.Profile .ant-upload-list-picture-card-container {
  margin: 0;
  width: 100%;
  height: 11em;
}

.Profile .ant-upload-list-picture-card-container .anticon.anticon-picture {
  padding-top: 15%;
}

.Profile .ant-upload-list-picture-card-container .ant-upload-list-item-name {
  bottom: 40px;
}

.Profile .ant-upload-list-item-name {
  white-space: normal;
}

.Profile .ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(0, 0, 0, 0.1);
}

/* Styles for Select Preferences step */
.Profile .ant-checkbox-group {
  width: 100%;
}

.Profile .ant-checkbox-group .ant-checkbox-wrapper {
  margin-bottom: 2em;
}

.Profile .footer-container .preview-button {
  margin-right: 1em;
}

.Profile .loading-indicator {
  display: flex;
  flex-direction: column;
}

.Profile .loading-indicator .anticon-loading {
  font-size: 3em;
}

.Profile .loading-indicator .loading-text {
  margin-top: 1em;
  text-align: center;
}

.Profile .screener-title {
  margin-bottom: 0;
}

.Profile .instructions .initial-question {
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}

.Profile .ant-typography blockquote {
  border-left: 4px solid #55a9a2;
  margin-top: 0;
  font-size: 0.95em;
}

.Profile .ant-typography blockquote p {
  margin-bottom: 0;
}

.Profile .anticon.anticon-info-circle {
  color: #55a9a2;
  font-size: 1.125em;
}

.Profile .ant-input-number.ant-input-number-lg,
.Profile .ant-input-number-affix-wrapper {
  width: 100%;
}

.Profile .FormElements-element-switch + .FormElements-element-input {
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .Profile .ant-upload.ant-upload-drag {
    width: 65%;
  }
  .Profile .ant-upload-list.ant-upload-list-picture-card {
    width: 30%;
  }
  .Profile .ant-upload-list-picture-card-container {
    height: 12.5em;
  }
  .Profile .ant-upload-list-picture-card-container .anticon.anticon-picture {
    padding-top: 30%;
  }
  .Profile .ant-upload-list-picture-card-container .ant-upload-list-item-name {
    bottom: 60px;
  }

  .Profile .footer-container .next-button {
    width: 100%;
  }

  .Profile .ant-typography blockquote p {
    display: inline;
  }

  .Profile .ant-col-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .Profile .footer-container .preview-button {
    margin-bottom: 1em;
  }

  .Profile .footer-container .preview-button,
  .Profile .footer-container .search-button {
    width: 100%;
  }
}
