.GroupList .ant-badge {
  width: 100%;
}

.GroupList .group {
  display: flex;
  padding: 1em;
  flex-direction: column;
  border-radius: 5px;
  margin: 1em 0.5em;
}

.GroupList .group.selected,
.GroupList .group.selected:hover {
  background-color: #f0f0f0;
}

.GroupList .group:hover {
  background-color: #d9d9d9;
}

.GroupList .group .group__avatar-names-container {
  display: flex;
  align-items: center;
}

.GroupList .group__member-names,
.GroupList .group__last-message {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.GroupList .group__member-names {
  margin-left: 0.5em;
}

.GroupList .group__last-message,
.GroupList .message-timestamp .timestamp__date {
  margin-top: 0.5em;
  padding-bottom: 0.125em;
}

.GroupList .message-timestamp {
  font-size: 0.8em;
}

.GroupList .message-timestamp .timestamp__time {
  margin-top: 0.25em;
}

@media all and (max-width: 800px) {
  .GroupList .group.selected {
    background-color: transparent;
  }
  .GroupList .group {
    border-radius: 0;
    border-bottom: 1px solid #d9d9d9;
  }
}
