.TermsOfUse__container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.TermsOfUse__container .TermsOfUse__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text {
  text-align: center;
  margin-bottom: 1em;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text p {
  text-align: center;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text h1,
.TermsOfUse__container .TermsOfUse__content .TermsOfUse__title-text p {
  font-weight: bold;
  margin: 0;
}

.TermsOfUse__container .TermsOfUse__content h1,
.TermsOfUse__container .TermsOfUse__content h2,
.TermsOfUse__container .TermsOfUse__content h3 {
  font-size: 1em;
}

.TermsOfUse__container .TermsOfUse__content p {
  line-height: 2;
  text-align: justify;
}

.TermsOfUse__container .TermsOfUse__content p + p {
  margin-top: 1em;
}

.TermsOfUse__container .TermsOfUse__content .TermsOfUse__contact-info span {
  display: block;
}

.TermsOfUse__container .TermsOfUse__content .text-indent--2 {
  text-indent: 2em;
}

.TermsOfUse__container .TermsOfUse__content .text-indent--3 {
  text-indent: 3em;
}

.TermsOfUse__container .TermsOfUse__content .italic {
  font-style: italic;
}

@media all and (max-width: 800px) {
  .TermsOfUse__container {
    padding-top: 5em;
  }
  .TermsOfUse__container .TermsOfUse__content {
    padding: 3em;
  }
}
