.Messages .message-container {
  margin-bottom: 3em;
  align-items: flex-start;
}

.Messages .group-messages-header {
  position: fixed;
  right: 0;
  width: 80%;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  z-index: 1;
}

.Messages .group-messages-container {
  padding-top: 7em;
}

.Messages .message-container.is-author {
  flex-direction: row;
  text-align: right;
}

.Messages .message-container.is-not-author {
  flex-direction: row-reverse;
}

.Messages .message-text {
  border: 1px solid lightgray;
  padding: 0.5em 1em;
  display: inline-block;
}

.Messages .message-container.is-author .message-text {
  margin-left: auto;
}

.Messages .message-author {
  display: flex;
  align-items: center;
}

.Messages .message-container.is-author .message-author {
  margin-left: 1em;
}

.Messages .message-container.is-not-author .message-author {
  margin-right: 1em;
  flex-direction: row-reverse;
  margin-left: 2em;
}

.Messages .message-container.is-author .message-author .author-name {
  margin-right: 0.5em;
}

.Messages .message-container.is-not-author .message-author .author-name {
  margin-left: 0.5em;
}

.Messages .message-col {
  margin-left: 0;
  max-width: 100%;
  flex: auto;
}

.Messages .input-container {
  width: 80%;
  margin-top: 1em;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 1em 2em;
}

.Messages .ant-page-header-back {
  display: none;
}

@media all and (max-width: 800px) {
  .Messages .input-container,
  .Messages .group-messages-header {
    width: 100%;
  }
  .Messages .ant-page-header-back {
    display: block;
  }

  .Messages .group-messages-container {
    margin-bottom: 7em;
  }
}
