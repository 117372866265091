.FormElements-element.datePicker .ant-picker {
  width: 100%;
}

.FormElements-input-tags {
  margin-top: 0.5em;
}

.FormElements .ant-select {
  width: 100%;
}

.FormElements .upload-dragger-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormElements .upload-dragger-container .upload-dragger-label {
  border: 1px solid #d9d9d9; /* antd gray-5 from https://ant.design/docs/spec/colors */
}

.FormElements .upload-dragger-container .upload-dragger-label:focus,
.FormElements .upload-dragger-container .upload-dragger-label:hover {
  outline: 0; /* antd gray-5 from https://ant.design/docs/spec/colors */
  border: 1px solid #40a9ff;
}

.FormElements .upload-dragger-container .upload-dragger-label {
  width: 100%;
  padding: 0em 0 1em;
}

.FormElements .upload-dragger-container .upload-dragger-icon,
.FormElements .upload-dragger-container .upload-dragger-title,
.FormElements .upload-dragger-container .upload-dragger-subtitle {
  display: block;
  text-align: center;
}

.FormElements .upload-dragger-container .upload-dragger-icon {
  font-size: 42px;
}

.FormElements .upload-dragger-container .upload-dragger-icon,
.FormElements .upload-dragger-image-icon--placeholder,
.FormElements .upload-dragger-image-icon--loading {
  color: #40a9ff; /* antd blue-5 from https://ant.design/docs/spec/colors */
}

.FormElements .upload-dragger-container .upload-dragger-title {
  font-size: 1.25em;
}

.FormElements .upload-dragger-container .upload-dragger-subtitle {
  color: rgba(0, 0, 0, 0.45);
}

.FormElements .upload-dragger-image {
  width: 10em;
  margin-left: 1em;
}

.FormElements .upload-dragger-image-icon--placeholder,
.FormElements .upload-dragger-image-icon--loading {
  text-align: center;
  margin-bottom: 1em;
  font-size: 2.25em;
  opacity: 0.7;
}

.FormElements .upload-dragger-image-placeholder {
  text-align: center;
  padding: 1em 1em 2em;
  border: 1px solid #d9d9d9;
  height: 100%;
}

.FormElements .upload-image-final {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.FormElements .upload-image-final__icon {
  text-align: right;
}

.FormElements .upload-image-final__image {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
  min-height: 10em;
}

.FormElements .upload-dragger-input-element {
  display: none;
}

.FormElements .upload-dragger-error-message {
  margin-top: 1em;
}

.FormElements .FormElements-element-radio {
  margin-top: 1em;
}

.FormElements .FormElements-radio-label {
  display: block;
}

.FormElements .FormElements-element-switch {
  display: flex;
}

.FormElements .FormElements-switch-label {
  margin-right: 0.5em;
}

.FormElements .float-label {
  position: relative;
  display: inline-block;
  width: 100%;
}

.FormElements .label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.FormElements .label.inline {
  color: gray;
}

.FormElements .label.above {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  z-index: 2;
}

.FormElements .label .required {
  color: red;
  font-family: monospace;
}

@media only screen and (max-width: 768px) {
  .FormElements .upload-dragger-container .upload-dragger-title {
    font-size: 1em;
  }
  .FormElements .upload-dragger-container .upload-dragger-subtitle {
    font-size: 0.75em;
  }
  .FormElements .upload-dragger-image-placeholder {
    padding: 1em;
  }
}
