.Messaging {
  display: flex;
  min-height: 80vh;
}

.Messaging .GroupList-container {
  width: 20%;
  cursor: pointer;
  border-right: 1px solid #d9d9d9;
  position: fixed;
  height: 100%;
}

.Messaging .GroupList-container:hover {
  overflow: auto;
}

.Messaging .Messages-container {
  width: 80%;
  margin-left: 20%;
  padding: 0 0 7em;
}

.Messaging .no-messages {
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 800px) {
  .Messaging .GroupList-container {
    width: 100%;
    display: none;
    position: static;
  }
  .Messaging .GroupList-container.is-visible {
    display: block;
  }
  .Messaging .Messages-container {
    width: 100%;
    padding: 0 1em;
    margin: 0 auto;
    display: none;
  }
  .Messaging .Messages-container.is-visible {
    display: block;
  }
}
