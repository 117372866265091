.search-providers {
  display: flex;
  min-height: 90vh;
}

.search-providers .section-header {
  font-size: 1.5em;
}
.search-providers .clear-filters {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 2em;
}
.search-providers .more-filters {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.search-providers .filters-section {
  padding: 1em 2em;
}

.search-providers .facet-header {
  margin-bottom: 1em;
  font-size: 1.125em;
}

.search-providers .facet-header-slider {
  margin-bottom: 0.4em;
  font-size: 1.125em;
}

.search-providers .disable-slider {
  font-size: 0.75em;
  margin-left: 0.5em;
}

.search-providers .filters-checkbox {
  margin-bottom: 1em;
}

.filters-checkbox {
  text-transform: capitalize;
}

.search-providers .providers-section {
  padding: 1em 2em;
  flex: 1 0 80%;
}

.search-providers .provider {
  margin-bottom: 2em;
  width: 31%;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
}

.search-providers .provider-badge {
  position: absolute;
  top: 1em;
  left: 1em;
}

.search-providers .message-card-action {
  display: flex;
  justify-content: center;
}

.search-providers .message-card-action .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.search-providers .message-card-action .ant-checkbox {
  display: none;
}

.search-providers .message-card-action .ant-checkbox.ant-checkbox-checked {
  display: block;
}

.search-providers .provider-image {
  height: 20em;
  background-size: cover;
  background-position: center;
}

.search-providers .ant-card .ant-skeleton.provider-image {
  display: flex;
}

.search-providers .ant-card .ant-skeleton.provider-image .ant-skeleton-button {
  flex: 1;
  padding-bottom: 80%;
}

.search-providers .provider-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.search-providers .provider-details .provider-name {
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.125em;
  margin-bottom: 0;
  /* Treatment for long names */
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.search-providers .provider-details .provider-name span {
  margin-right: 0.25em;
}

.search-providers .provider-details .provider-responsiveness {
  margin: 1em 0;
}

.search-providers
  .provider-details
  .provider-responsiveness
  .provider-responsiveness-header {
  color: #00000073;
  font-weight: bold;
}

.search-providers
  .provider-details
  .provider-responsiveness
  .provider-responsiveness-body {
  font-size: 0.75em;
}

.search-providers
  .provider-details
  .provider-responsiveness
  .provider-responsiveness-body
  .ant-tag {
  margin-top: 0.25em;
}

.search-providers .provider .provider-pronouns {
  font-size: 1.0125em;
}

.search-providers .provider .provider-description {
  margin-top: 0.5em;
}

.search-providers .mobile-filters-button {
  display: none;
}

.search-providers .ant-rate {
  font-size: 1em;
  color: #55a9a2;
}

.search-providers .ant-rate-star:not(:last-child) {
  margin-right: 0;
}

.search-providers .ratings-fraction {
  font-size: 95%;
}

.search-providers .providers-list {
  display: flex;
  flex-wrap: wrap;
}

.search-providers .overall-ratings {
  text-align: right;
}

.search-providers .overall-ratings-label {
  font-size: 75%;
}

.search-providers .error-trigger {
  width: 100%;
  height: 100%;
  position: absolute;
}

.search-providers .ant-card-actions > li {
  color: black;
}

.search-providers .ant-card-body {
  flex: 1;
}

@media all and (max-width: 1024px) {
  .search-providers .providers-section {
    padding: 1em 2em;
    flex: 1 0 75%;
  }
  .search-providers .provider {
    width: 48%;
  }
}

@media all and (max-width: 765px) {
  .search-providers {
    font-size: 80%;
    display: block;
  }

  .search-providers .filters-section {
    display: none;
  }

  .search-providers .mobile-filters-button {
    display: block;
    width: 90%;
    margin: 1em auto 0;
  }
  .search-providers-filters-drawer .ant-drawer-title {
    font-size: 1.5em;
  }
  .search-providers-filters-drawer .facet-header {
    margin-bottom: 1em;
    font-size: 1.125em;
  }
  .search-providers-filters-drawer .filters-checkbox {
    margin-bottom: 1em;
  }
  .search-providers-filters-drawer .clear-filters {
    text-decoration: underline;
    cursor: pointer;
  }

  .search-providers .provider {
    width: 100%;
  }

  .search-providers .provider-image {
    height: 45em;
  }

  .search-providers .ant-card-body {
    padding: 1em;
  }

  .search-providers .ant-card-body {
    min-height: auto;
  }
}

@media all and (max-width: 500px) {
  .search-providers .provider-image {
    height: 20em;
  }
}
