@import url("//cdn-images.mailchimp.com/embedcode/classic-10_7.css");
html {
  scroll-behavior: smooth;
}

.homepage__container h1 {
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepage__container h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepage__container h3 {
  margin-bottom: 0;
  font-weight: bold;
}

#jubily-interest-form {
  scroll-margin-top: 150px;
}

.homepage__container button,
#mc_embed_signup .button {
  background-color: #0072ff;
  border: none;
  color: white !important;
  padding: 0.5em 0.75em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 15em;
  font-size: 1.125em;
  margin-top: 1em;
  cursor: pointer;
  height: auto;
  line-height: initial;
}

.homepage__container button:hover,
#mc_embed_signup .button:hover {
  opacity: 0.8;
  background-color: #0072ff;
}

.homepage__container a {
  text-decoration: none;
  color: initial;
}

.homepage__container input {
  padding-left: 1em;
}

.homepage__container input:focus {
  box-shadow: 0.5px 0.5px #1f0c2f;
  outline: none;
}

#mc_embed_signup form {
  padding-left: 0;
}

#mc_embed_signup .mc-field-group + .mc-field-group {
  margin-left: 2%;
}
#mc_embed_signup .mc-field-group {
  width: 100%;
  padding-bottom: 0;
}

#mc_embed_signup .mc-field-group__container {
  padding-bottom: 1%;
}

#mc_embed_signup .mc-field-group.input-group ul li {
  flex: 0 1 50%;
}

#mc_embed_signup .indicates-required {
  margin-right: 0;
}

.flex {
  display: flex;
}

.flex--column {
  display: flex;
  flex-direction: column;
}

.flex--row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex__grow {
  flex: 1 1 auto;
}

.flex__wrap {
  flex-wrap: wrap;
}

.centered {
  text-align: center;
}

.justify-content__center {
  justify-content: center;
}
.justify-items__space-between {
  justify-content: space-between;
}

.justify-items__space-around {
  justify-content: space-around;
}

.justify-items__space-evenly {
  justify-content: space-evenly;
}

.align-self__center {
  align-self: center;
}

.align-items__center {
  align-items: center;
}

.align-items__flex-end {
  align-items: flex-end;
}

.align-items__baseline {
  align-items: baseline;
}

.bold {
  font-weight: bold;
}

.large-title-text {
  line-height: 1.125em;
}

.homepage__section-text {
  position: relative;
  right: 1.5em;
}

.homepage__section-image {
  width: 45%;
  border: 20px solid #ffc101;
  min-height: 550px;
  object-fit: cover;
  background-color: lightslategray;
}

.homepage__section-image.position-right {
  object-position: right;
}

.homepage__section.image-right {
  background-color: #f1f7ff;
}

.homepage__section.image-right .homepage__section-image {
  border-right: 0;
}

.homepage__section.image-left .homepage__section-image {
  border-left: 0;
}

.homepage__section.position-relative {
  position: relative;
}

.homepage__section.position-relative .phone-image {
  position: absolute;
  width: 18%;
  bottom: 3%;
  right: 40%;
}

.homepage__section.section--image-text-block.gradient-down-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
}

.homepage__section.section--image-text-block.gradient-up-light {
  background: rgb(241, 247, 255);
  background: linear-gradient(
    0deg,
    rgba(241, 247, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.homepage__section.section--image-text-block.gradient-left-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
}

.homepage__section.next-step {
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 9em 0;
}

.homepage__section.next-step .logos-container {
  width: 54%;
}

.homepage__section.next-step img.nsf {
  width: 100px;
  margin-right: 20px;
  margin-left: -5px;
}

.homepage__section.next-step img.blackstone {
  object-fit: contain;
  width: 300px;
}

.homepage__section.next-step img.black-ambition {
  width: 250px;
  margin-left: -25px;
}

.homepage__section.next-step img.berkeley-law {
  height: 75px;
}

.homepage__section.next-step img.skydeck {
  margin-left: -10px;
  height: 85px;
}

.homepage__section.next-step .homepage__section-text {
  width: 50%;
  border-left: 20px solid #ffc101;
  margin-right: 0;
}

.homepage__section.next-step .homepage__section-text .form-container {
  width: 80%;
  margin-left: auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes from-left {
  0% {
    right: 1.5em;
  }
  100% {
    right: 0;
  }
}

.homepage__section.section--image-text-block {
  padding: 60px 0;
  justify-content: space-between;
}

.homepage__container {
  margin: 0 auto;
  max-width: 120em;
  width: 100%;
}

.homepage__section + .homepage__section {
  margin-top: 5em;
}

.homepage__section.image-left .homepage__section-text {
  margin-right: 10em;
}

.homepage__section.image-right .homepage__section-text {
  margin-left: 10em;
}

.homepage__section-text {
  width: 30%;
}

.homepage__section-block-text {
  line-height: 2em;
  width: 90%;
  margin: 0 auto;
  max-width: 900px;
}

.homepage__section-block-text .large-title-text {
  text-align: center;
  font-size: 2em;
}

.block-text__sections {
  padding: 2em;
  justify-content: space-between;
}

.block-text__section {
  width: 48%;
  background-color: #f1f7ff;
  padding: 2em;
  position: relative;
}

.block-text__section .coming-soon-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: #ff4c4c;
  border-radius: 100px;
  font-weight: bold;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 15px;
}

.block-text__section ul {
  padding-inline-start: 20px;
}

.block-text__section ul li {
  padding: 10px 0;
}

.homepage__container p {
  margin-top: 0.5em;
}

.homepage__section-title {
  width: 45%;
}

.homepage__container p,
.homepage__container li {
  font-size: 1.25em;
  line-height: 1.75;
}

@media all and (max-width: 1150px) {
  .homepage__container h1 {
    font-size: 2em;
  }

  .homepage__container h2 {
    font-size: 1.75em;
  }

  .homepage__container p,
  .homepage__container li {
    font-size: 1.125em;
  }

  .homepage__section.image-right .homepage__section-text {
    margin-left: 2em;
  }

  .homepage__section.image-left .homepage__section-text {
    margin-right: 2em;
  }

  .homepage__section-text {
    width: 40%;
  }

  .homepage__section-block-text .large-title-text {
    text-align: left;
  }

  .homepage__section-block-text .large-title-text br {
    display: none;
  }

  .block-text__sections {
    padding: 0;
  }

  .homepage__section.next-step {
    width: 95%;
  }

  .homepage__section.next-step .homepage__section-text {
    margin-left: 2em;
  }

  .homepage__section.next-step img.nsf,
  .homepage__section.next-step img.blackstone,
  .homepage__section.next-step img.black-ambition,
  .homepage__section.next-step img.berkeley-law {
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .homepage__section.next-step img.nsf {
    width: 20%;
    margin-left: 0;
  }

  .homepage__section.next-step img.black-ambition {
    margin-left: -10px;
  }

  .homepage__section.next-step img.skydeck {
    width: 100%;
    height: auto;
    margin-left: auto;
  }
}

@media all and (max-width: 800px) {
  .homepage__section.position-relative .phone-image {
    display: none;
  }

  .homepage__container button,
  #mc_embed_signup .button {
    width: 100%;
  }
  .homepage__container {
    overflow: hidden;
  }

  .homepage__section.image-left,
  .homepage__section.image-right {
    flex-direction: column-reverse;
  }

  .homepage__section-image {
    width: 85%;
    border: 0;
    border-bottom: 20px solid #ffc101;
    min-height: auto;
  }

  .homepage__section-block-text .large-title-text {
    text-align: left;
  }

  .homepage__container p,
  .homepage__container li {
    font-size: 1.125em;
  }

  .homepage__section-text {
    width: auto;
  }

  .homepage__section-text {
    padding-left: 3em;
    padding-right: 2em;
  }

  .homepage__section-block-text {
    padding: 2em;
  }

  .block-text__sections {
    flex-direction: column;
    padding: 3em 0 0;
  }

  .block-text__section {
    margin-bottom: 20px;
    width: 100%;
  }

  .homepage__section-title {
    width: 100%;
  }

  .homepage__section-title {
    padding-bottom: 0.5em;
  }

  .large-title-text {
    max-width: 100%;
    font-size: 2.5em;
    line-height: 1.25em;
  }

  .homepage__section.section--image-text-block .homepage__section-text {
    padding: 1em;
    margin: 0 auto 0 10%;
    width: 90%;
  }

  .homepage__container #mc_embed_signup .mc-field-group.input-group ul.flex {
    display: block;
  }

  .homepage__section.next-step {
    width: 90%;
    flex-direction: column;
    padding: 5em 1em 0;
  }

  .homepage__section.next-step .logos-container {
    width: 100%;
  }

  .homepage__section.next-step .homepage__section-text {
    border: none;
    border-top: 20px solid #ffc101;
    padding: 30px 0 0;
    margin: 30px 0 0 40px;
    width: 100%;
  }

  .homepage__section.next-step .homepage__section-text .form-container {
    width: 100%;
  }

  #jubily-interest-form {
    scroll-margin-top: 100px;
  }
}
