@import url("https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@import "~antd/dist/antd.css";

body {
  font-family: "Gelasio", serif;
}

.ant-result.ant-result-404 {
  min-height: 80vh;
}
