.interest-form .mc-field-group__container .FormElements {
  width: 100%;
}

.interest-form .FormElements-element-inputRow {
  margin-bottom: 1em;
}

.interest-form .mc-field-group {
  margin-top: 1em;
}

.interest-form .ant-space.ant-space-horizontal.ant-space-align-center {
  align-items: flex-start;
}
