.Preview img {
  width: 100%;
}

.Preview .profile-name {
  margin-bottom: 0;
}

.Preview .pronoun {
  text-transform: capitalize;
}

.Preview .image-container {
  text-align: center;
  margin-right: 1em;
  max-width: 12em;
}

.Preview .responsive-areas {
  display: flex;
  flex-direction: column;
}

.Preview .profile-name {
  font-weight: bold;
  font-size: 1.625em;
  line-height: 1.125em;
}

.Preview .ant-avatar.ant-avatar-circle.ant-avatar-image {
  width: 10em;
  height: 10em;
}

.Preview .profile-heading {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Preview .profile-heading.with-image {
  max-width: 50%;
}

.Preview .profile-heading p {
  margin-bottom: 0;
}

.Preview .ant-collapse {
  width: 100%;
  margin-top: 1em;
}

.Preview .ant-collapse .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 15%;
  padding: 1em;
}

.Preview .ant-tag {
  margin-bottom: 0.125em;
}

.Preview .collapse-panel-header {
  text-decoration: underline;
  font-weight: bold;
}

.Preview .fee-text {
  font-weight: bold;
  font-size: 0.8em;
}

@media all and (max-width: 768px) {
  .Preview .ant-avatar.ant-avatar-circle.ant-avatar-image {
    width: 8em;
    height: 8em;
  }

  .profile-preview-drawer .ant-drawer-body {
    padding: 1em;
  }

  .profile-preview-drawer
    .ant-descriptions-bordered
    .ant-descriptions-item-label {
    padding: 0.5em;
  }

  .profile-preview-drawer
    .ant-descriptions-bordered
    .ant-descriptions-item-content {
    padding: 0.5em 1em;
  }
}
