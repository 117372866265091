.PrivacyPolicy {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 247, 255, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 5em 0 7em;
}

.PrivacyPolicy .PrivacyPolicy__content {
  background-color: white;
  max-width: 80vw;
  margin: 0 auto;
  padding: 2em 5em;
  border-radius: 8px;
}

.PrivacyPolicy .PrivacyPolicy__content h1 {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  font-weight: bold;
}

.PrivacyPolicy .PrivacyPolicy__content h2,
.PrivacyPolicy .PrivacyPolicy__content h3 {
  font-size: 1em;
  font-weight: bold;
}

.PrivacyPolicy .PrivacyPolicy__content p {
  line-height: 2;
  text-align: justify;
}

.PrivacyPolicy .PrivacyPolicy__content p + p {
  margin-top: 1em;
}

.PrivacyPolicy .PrivacyPolicy__content .TermsOfUse__contact-info span {
  display: block;
}

.PrivacyPolicy .PrivacyPolicy__content .text-indent--2 {
  text-indent: 2em;
}

.PrivacyPolicy .PrivacyPolicy__content .text-indent--3 {
  text-indent: 3em;
}

.PrivacyPolicy .PrivacyPolicy__content .italic {
  font-style: italic;
}

@media all and (max-width: 800px) {
  .PrivacyPolicy {
    padding-top: 5em;
  }
  .PrivacyPolicy .PrivacyPolicy__content {
    padding: 3em;
  }
}
